import React from "react";
import { motion } from "framer-motion";
import laserGame1 from "../assets/laser2.jpg";

const Laser = () => {
  return (
    <div className=" w-screen py-40 px-0  flex flex-col items-center gap-10 text-5xl font-serif bg-b text-w">
      <motion.h2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        className="mb-10 lg:text-7xl text-c2 underline"
      >
        {" "}
        Laser game
      </motion.h2>
      <section className="flex flex-col-reverse lg:flex-row w-full items-start gap-4 pb-20">
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.5, delay: 1 } }}
          className="font-sans text-xl lg:pl-40 w-full px-10"
        >
          <span className="bg-c2 p-0 px-2 rounded-sm">
            {" "}
            Amateurs de sensations fortes
          </span>
          , venez vous defier, usez de stratégie pour terrasser l’adversaire
          dans notre labyrinthe à deux niveaux de 600m2. Affrontez-vous avec un
          équipement de jeu laser et faites le plein d’adrénaline !
          <br />
          <br />
          Jeu accessible dès 8 ans, nous prenons garde à ne pas mélanger les
          plus jeunes et les adultes.
          <br />
          <br />
          <span className="text-c3">
            Le laser game peut accueillir de 4 à 20 personnes par session, une
            session dure 20min. Nous vous invitons à nous joindre par téléphone
            ( 02 23 16 97 68 ) pour réserver vos créneaux horaires. Vous devez
            impérativement vous présenter 15min avant l’horaire de la session.
          </span>
          <br />
          <br />
          <span className="font-serif text-c2 text-3xl p-0 px-2 rounded-sm">
            Le principe du laser game
          </span>
          <br />
          <br />
          Équipé d'un matériel hi-tech, vous évoluerez dans une des arènes de
          600m2 à deux niveaux, des labyrinthes à faire peur, et vous devrez
          tenter de marquer un maximum de points en touchant les cibles de vos
          adversaires. <br />
          <br />
          Vous pourrez la jouer solo ou en tribu et les plus forts seront donc
          les plus stratèges, les plus téméraires, les plus habiles, les mieux
          cachés. Que les meilleurs gagnent ! <br />
          <br /> Totalement inoffensive, cette activité plaira à toutes et tous,
          de 7 à 77 ans.
        </motion.p>
        <motion.div className="w-3/5 lg:sticky top-44 mx-auto overflow-hidden grid place-content-center">
          <motion.img
            initial={{ opacity: 0, x: 100 }}
            animate={{
              opacity: 1,
              x: 0,
              transition: { duration: 1, ease: "easeIn" },
            }}
            src={laserGame1}
            alt="pistes de bowling"
            className="w-full"
          />
        </motion.div>
      </section>
    </div>
  );
};

export default Laser;
