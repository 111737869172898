import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { motion } from "framer-motion";
import Swal from "sweetalert2";
import Confetti from "react-confetti";
import home3 from "../assets/home3.jpeg";
import { IoDocumentTextOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const BirthdayForm = () => {
  const [confetti, setConfetti] = useState(false);
  const dateAndTimeFormat = (dateAndTime) => {
    const date = new Date(dateAndTime);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes() === 0 ? "00" : date.getMinutes();
    return `${day}/${month}/${year} à ${hours}:${minutes}`;
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      lastName: "",
      childName: "",
      email: "",
      phone: "",
      dateAndTime: "",
      numberOfChildren: "",
      activities: "1",
      activity1: "",
      activity2: "none",
      activity3: "none",
      ageRange: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Requis"),
      childName: Yup.string().required("Requis"),
      email: Yup.string().email("Invalid email address").required("Requis"),
      phone: Yup.string().required("Requis"),
      dateAndTime: Yup.string().required("Requis"),
      numberOfChildren: Yup.number().required("Requis").positive().integer(),
      activities: Yup.string().required("Requis"),
      activity1: Yup.string()
        .required("Requis")
        .oneOf(["Bowling", "Laser game", "Trampoline"], "Activité invalide"),
      activity2: Yup.string().when("activities", {
        is: "2" || "3",
        then: (schema) =>
          schema
            .required("Requis")
            .oneOf(
              ["Bowling", "Laser game", "Trampoline"],
              "Activité invalide"
            ),
        otherwise: (schema) =>
          schema.oneOf(
            ["Bowling", "Laser game", "Trampoline", "none"],
            "Activité invalide"
          ),
      }),
      activity3: Yup.string().when("activities", {
        is: "3",
        then: (schema) =>
          schema
            .required("Requis")
            .oneOf(
              ["Bowling", "Laser game", "Trampoline"],
              "Activité invalide"
            ),
        otherwise: (schema) =>
          schema.oneOf(
            ["Bowling", "Laser game", "Trampoline", "none"],
            "Activité invalide"
          ),
      }),
      ageRange: Yup.string()
        .required("Requis")
        .oneOf(
          ["5-7 ans", "7-10 ans", "10-12 ans", "12-15 ans", "15 ans et +"],
          "Âge invalide"
        ),
    }),
    onSubmit: async (values) => {
      try {
        const {
          name,
          lastName,
          childName,
          email,
          phone,
          dateAndTime,
          numberOfChildren,
          activities,
          ageRange,
        } = values;
        const htmlContent = `
        Nom: ${lastName.toUpperCase()} ${name} <br>
        Prénom du fêté: ${childName}<br>
        Téléphone: ${phone}<br>
        Date et heure demandées: ${dateAndTimeFormat(dateAndTime)}<br>
        Nombre d'enfants: ${numberOfChildren}<br>
        Nombre d'activités: ${activities}<br>
        1ère activité: ${values.activity1}<br>
        2ème activité: ${activities === "1" ? "X" : values.activity2}<br>
        3ème activité: ${
          activities === "1" || activities === "2" ? "X" : values.activity3
        }<br>
        Âges: ${ageRange}
      `;
        const subject = "Anniversaire - Formulaire de pré-réservation";
        await sendBdayRequest(htmlContent, email, subject).then(() => {
          setConfetti(true);
        });
        Swal.fire({
          icon: "success",
          title: `Merci d'avoir pris le temps de remplir le formulaire!

              Nous vous contacterons dans les plus brefs délais 
              pour confirmer votre anniversaire.`,
          confirmButtonColor: "#56bbe5",
          confirmButtonText: "Fermer",
        });
        formik.resetForm();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops! Une erreur s'est produite. Veuillez recommencer.",
        });
      }
    },
  });

  const sendBdayRequest = async (htmlContent, replyTo) => {
    const subject = "Demande d'anniversaire";
    try {
      const response = await axios.post(
        "https://westparc-server.vercel.app/send-email",
        {
          htmlContent,
          replyTo,
          subject,
        }
      );
      console.log(response);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  useEffect(() => {
    setConfetti(true);
    setTimeout(() => {
      setConfetti(false);
    }, 5000);
  }, []);

  return (
    <motion.div className="h-fit text-lg text-w flex flex-col-reverse lg:flex-row items-start w-screen py-40 px-10 lg:px-20">
      <motion.div className="w-full pb-20 lg:pr-20 z-10">
        <h2 className="text-w lg:block hidden font-serif text-center text-5xl lg:text-7xl mx-auto mb-10">
          Anniversaires
        </h2>
        <p className="text-justify text-lg text-w mb-10">
          <img
            src={home3}
            alt="fête d'enfants"
            className="float-right hidden lg:block mb-10 w-full lg:w-1/2 rounded-md lg:ml-4 lg:mb-2"
          />
          Viens fêter ton anniversaire avec tes amis et ta famille à West Parc
          et profite de toutes nos activités.
          <br />
          <br />
          Notre Formule anniversaire est proposée à Partir de 7 ans pour un
          minimum de 4 enfants. Elle peux se faire tous les jours. Nous vous
          invitons à remplir le formulaire de pré réservation ici joint. Nous
          vous rappellerons pour valider et confirmer votre réservation. Nous
          vous conseillons aussi de réserver un mois à l’avance pour les samedi
          et mercredi : nous sommes vite complet sur ces créneaux !
          <br />
          <br />
          Vous pouvez choisir vos activités : Laser Game, Trampoline, Bowling.{" "}
          <br />
          Vous déterminez le nombre d’activité que vous souhaitez faire. <br />
          Nous vous réserverons une table pour vos invités, la formule comprend
          un part de gateau au chocolat, un bol de bonbons, des carafes de sirop
          à volonté. <br />
          Bien sûr, vous pouvez amener en sus vos propres gateaux et bonbons.
          <br />
          <br />
          <span className="italic text-c2">
            {" "}
            Pour information, l’établissement ne pourra accepter les enfants que
            sous l’encadrement d’au moins un adulte.
          </span>
        </p>
        <h2 className="text-c3 font-serif text-center text-5xl mx-auto mb-10">
          Tarifs
        </h2>
        <motion.div className="text-w text-sm md:text-2xl grid font-sans grid-cols-3 gap-2 w-full">
          <motion.div />
          <h2 className="border-2 rounded-tl-md font-serif text-center border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-2 lg:px-4 py-2 transition-all duration-500">
            Semaine*
          </h2>
          <h2 className="border-2 rounded-tr-md font-serif text-center whitespace-nowrap border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-2 lg:px-4 py-2 transition-all duration-500">
            {" "}
            Week-end**
          </h2>
          <p className="border-2 rounded-tl-md border-w font-serif shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-2 lg:px-4 py-2 transition-all duration-500 text-center">
            1 activité
          </p>{" "}
          <p className="border-2 border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-2 lg:px-4 py-2 transition-all duration-500 text-center">
            10€/enfant
          </p>{" "}
          <p className="border-2 border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-2 lg:px-4 py-2 transition-all duration-500 text-center">
            12€/enfant
          </p>{" "}
          <p className="border-2 border-w font-serif shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-2 lg:px-4 py-2 transition-all duration-500 text-center">
            2 activités
          </p>{" "}
          <p className="border-2 border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-2 lg:px-4 py-2 transition-all duration-500 text-center">
            15€/enfant
          </p>{" "}
          <p className="border-2 border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-2 lg:px-4 py-2 transition-all duration-500 text-center">
            17€/enfant
          </p>
          <p className="border-2 rounded-bl-md border-w font-serif shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-2 lg:px-4 py-2 transition-all duration-500 text-center">
            3 activités
          </p>{" "}
          <p className="border-2 border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-2 lg:px-4 py-2 transition-all duration-500 text-center">
            19€/enfant
          </p>{" "}
          <p className="border-2 rounded-br-md border-w shadow-[0px_0px_5px_5px_#FD6E88,_0px_0px_5px_5px_#FD6E88_inset] hover:shadow-[0px_0px_10px_10px_#56bbe5_inset,_0px_0px_10px_10px_#56bbe5] px-2 lg:px-4 py-2 transition-all duration-500 text-center">
            21€/enfant
          </p>
        </motion.div>
        <p className="mt-2 text-sm">* hors vacances scolaires</p>
        <p className="text-sm">
          ** week-end, jours fériés et vacances scolaires
        </p>
        <p className="w-full font-sans mt-10 text-w">
          <span className="font-serif  text-c2">Bowling</span> : Location de
          chaussures comprise dans le tarif. <br />
          Chaussettes obligatoire. (en vente sur place 1.50€)
          <br />
          <br />
          <span className="font-serif text-c3">Trampoline</span> : Chaussettes
          antidérapantes obligatoires. (en vente sur place 2€)
        </p>
        <Link
          className="text-c2 border border-c2 px-4 py-2 rounded-md hover:bg-c2 hover:text-b w-fit flex items-center justify-center gap-2 mt-20 mx-auto font-sans text-xl lg:text-3xl"
          target="_blank"
          rel="noreferer noopener"
          to="/carte-invitation-west-parc.pdf"
        >
          Imprimer tes invitations pour ton anniversaire{" "}
          <IoDocumentTextOutline size={30} />
        </Link>
      </motion.div>
      <div className="fixed inset-0 -z-0 bg-t">
        {confetti && (
          <Confetti
            width={window.innerWidth}
            height={window.innerHeight}
            recycle={false}
            numberOfPieces={1000}
          />
        )}
      </div>
      <motion.form
        layout
        onSubmit={formik.handleSubmit}
        className="w-full h-[70vh] lg:mb-0 mb-10 overflow-y-scroll lg:overscroll-contain lg:w-screen lg:sticky shadow-[0px_0px_10px_10px_#56bbe5] top-40 max-w-[400px] p-4 border-2 rounded-lg border-c2 font-sans flex flex-col items-center gap-4 mx-auto lg:ml-auto z-10"
      >
        <motion.div layout className="h-fit">
          <motion.h2
            layout
            className="text-w font-serif mb-4 text-center text-4xl"
          >
            Formulaire de pré-réservation
          </motion.h2>
          <motion.div
            layout
            className="w-full h-fit text-lg text-b flex flex-col md:flex-row items-center justify-center gap-4"
          >
            <motion.div
              layout
              className="w-full flex flex-col  items-center justify-center gap-4"
            >
              <motion.div className="flex flex-row items-center gap-2">
                <motion.div className="flex flex-row w-full  gap-5 items-center">
                  <motion.input
                    layout
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="   Nom..."
                    className=" w-full h-10 border-2 border-c2 rounded-md px-4 uppercase"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                  />
                </motion.div>
                <motion.div className="flex flex-row  w-full gap-5 items-center">
                  <motion.input
                    layout
                    type="text"
                    name="name"
                    id="name"
                    placeholder="* Prénom..."
                    className={
                      formik.touched.name && formik.errors.name
                        ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                        : "w-full h-10 border-2 border-c2 rounded-md px-4 capitalize"
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <motion.div
                      layout
                      className="text-c1 text-sm lg:block hidden"
                    >
                      {formik.errors.name}
                    </motion.div>
                  ) : null}
                </motion.div>
              </motion.div>
              <motion.div className="flex flex-row  w-full gap-5 items-center">
                <motion.input
                  layout
                  type="text"
                  name="childName"
                  id="childName"
                  placeholder="* Prénom du fêté..."
                  className={
                    formik.touched.childName && formik.errors.childName
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 "
                      : "w-full h-10 border-2 border-c2 rounded-md px-4 "
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.childName}
                />
                {formik.touched.childName && formik.errors.childName ? (
                  <motion.div
                    layout
                    className="text-c1 text-sm lg:block hidden"
                  >
                    {formik.errors.childName}
                  </motion.div>
                ) : null}
              </motion.div>
              <motion.div className="w-full flex flex-row gap-5 items-center">
                <motion.input
                  layout
                  type="email"
                  name="email"
                  id="email"
                  placeholder="* Email..."
                  className={
                    formik.touched.email && formik.errors.email
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                      : "w-full h-10 border-2 border-c2 rounded-md px-4"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-c1 text-sm lg:block hidden ">
                    {formik.errors.email}
                  </p>
                ) : null}
              </motion.div>
              <motion.div className="w-full flex flex-row items-center gap-5">
                <motion.input
                  layout
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="* Téléphone..."
                  className={
                    formik.touched.phone && formik.errors.phone
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                      : "w-full h-10 border-2 border-c2 rounded-md px-4"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <p layout className="text-c1 text-sm lg:block hidden">
                    {formik.errors.phone}
                  </p>
                ) : null}
              </motion.div>
              <motion.div className="w-full flex flex-row items-center gap-5">
                <motion.input
                  layout
                  type="datetime-local"
                  name="dateAndTime"
                  id="dateAndTime"
                  placeholder="Date de l'anniversaire..."
                  className={
                    formik.touched.dateAndTime && formik.errors.dateAndTime
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                      : "w-full h-10 border-2 border-c2 rounded-md px-4"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dateAndTime}
                />
                {formik.touched.dateAndTime && formik.errors.dateAndTime ? (
                  <p layout className="text-c1 text-sm lg:block hidden">
                    {formik.errors.dateAndTime}
                  </p>
                ) : null}
              </motion.div>
              <motion.div className="w-full flex flex-row items-center gap-5">
                <motion.input
                  layout
                  type="number"
                  name="numberOfChildren"
                  id="numberOfChildren"
                  placeholder="* Nombre d'enfants..."
                  className={
                    formik.touched.numberOfChildren &&
                    formik.errors.numberOfChildren
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                      : "w-full h-10 border-2 border-c2 rounded-md px-4"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.numberOfChildren}
                />
                {formik.touched.numberOfChildren &&
                formik.errors.numberOfChildren ? (
                  <p layout className="text-c1 text-sm lg:block hidden">
                    {formik.errors.numberOfChildren}
                  </p>
                ) : null}
              </motion.div>
              <motion.fieldset
                layout
                className="w-full text-w flex flex-row items-center gap-5"
              >
                <legend className="underline font-serif">
                  Nombre d'activités
                </legend>
                <motion.div className="flex flex-row justify-between w-full">
                  <label htmlFor="oneActivity" className="">
                    1 Activité
                    <motion.input
                      layout
                      type="radio"
                      name="activities"
                      id="oneActivity"
                      checked={formik.values.activities === "1"}
                      className={
                        formik.touched.activities && formik.errors.activities
                          ? "border-c1 w-full h-4 border-2 rounded-md px-4 capitalize"
                          : "w-full h-4 border-2 border-c2 rounded-md px-4"
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value="1"
                    />
                  </label>
                  <label htmlFor="twoActivities" className="">
                    2 Activités
                    <motion.input
                      layout
                      type="radio"
                      name="activities"
                      id="twoActivities"
                      checked={formik.values.activities === "2"}
                      className={
                        formik.touched.activities && formik.errors.activities
                          ? "border-c1 w-full h-4 border-2 rounded-md px-4 capitalize"
                          : "w-full h-4 border-2 border-c2 rounded-md px-4"
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value="2"
                    />
                  </label>
                  <label htmlFor="threeActivities" className="">
                    3 Activités
                    <motion.input
                      layout
                      type="radio"
                      name="activities"
                      id="threeActivities"
                      checked={formik.values.activities === "3"}
                      className={
                        formik.touched.activities && formik.errors.activities
                          ? "border-c1 w-full h-4 border-2 rounded-md px-4 capitalize"
                          : "w-full h-4 border-2 border-c2 rounded-md px-4"
                      }
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value="3"
                    />
                  </label>
                </motion.div>
              </motion.fieldset>
              <motion.div
                layout
                className="w-full capitalize text-b flex flex-row gap-5 items-center"
              >
                <motion.select
                  layout
                  name="activity1"
                  id="activity1"
                  className={
                    formik.touched.activity1 && formik.errors.activity1
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                      : " w-full h-10 border-2 border-c2 rounded-md px-4"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.activity1}
                >
                  <option value="" disabled>
                    * Activité 1...
                  </option>
                  <option value="Bowling">Bowling</option>
                  <option value="Laser game">Laser game</option>
                  <option className="capitalize" value="Trampoline">
                    Trampoline
                  </option>
                </motion.select>
                {formik.touched.activity1 && formik.errors.activity1 ? (
                  <p layout className="text-c1 text-sm lg:block hidden">
                    {formik.errors.activity1}
                  </p>
                ) : null}
              </motion.div>
              {(formik?.values?.activities === "2" ||
                formik?.values?.activities === "3") && (
                <motion.div
                  layout
                  className="w-full capitalize text-b flex flex-row gap-5 items-center"
                >
                  <motion.select
                    layout
                    name="activity2"
                    id="activity2"
                    className={
                      formik.touched.activity2 && formik.errors.activity2
                        ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                        : " w-full h-10 border-2 border-c2 rounded-md px-4"
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.activity2}
                  >
                    <option value="none" disabled>
                      * Activité 2...
                    </option>
                    <option value="Bowling">Bowling</option>
                    <option value="Laser game">Laser game</option>
                    <option className="capitalize" value="Trampoline">
                      Trampoline
                    </option>
                  </motion.select>
                  {formik.touched.activity2 && formik.errors.activity2 ? (
                    <p layout className="text-c1 text-sm lg:block hidden">
                      {formik.errors.activity2}
                    </p>
                  ) : null}
                </motion.div>
              )}
              {formik?.values?.activities === "3" && (
                <motion.div
                  layout
                  className="w-full capitalize text-b flex flex-row gap-5 items-center"
                >
                  <motion.select
                    layout
                    name="activity3"
                    id="activity3"
                    className={
                      formik.touched.activity3 && formik.errors.activity3
                        ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                        : " w-full h-10 border-2 border-c2 rounded-md px-4"
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.activity3}
                  >
                    <option value="none" disabled>
                      * Activité 3...
                    </option>
                    <option value="Bowling">Bowling</option>
                    <option value="Laser game">Laser game</option>
                    <option value="Trampoline">Trampoline</option>
                  </motion.select>
                  {formik.touched.activity3 && formik.errors.activity3 ? (
                    <p layout className="text-c1 text-sm lg:block hidden">
                      {formik.errors.activity3}
                    </p>
                  ) : null}
                </motion.div>
              )}
              <motion.div className="w-full text-b flex flex-row gap-5 items-center">
                <motion.select
                  layout
                  name="ageRange"
                  id="ageRange"
                  className={
                    formik.touched.ageRange && formik.errors.ageRange
                      ? "border-c1 w-full h-10 border-2 rounded-md px-4 capitalize"
                      : " w-full h-10 border-2 border-c2 rounded-md px-4"
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ageRange}
                >
                  <option value="" disabled>
                    * Âges des enfants...
                  </option>
                  <option value="7-10 ans">7-10 ans</option>
                  <option value="10-12 ans">10-12 ans</option>
                  <option value="12-15 ans">12-15 ans</option>
                  <option value="15 ans et +">15 ans et +</option>
                </motion.select>
                {formik.touched.ageRange && formik.errors.ageRange ? (
                  <p layout className="text-c1 text-sm lg:block hidden ">
                    {formik.errors.ageRange}
                  </p>
                ) : null}
              </motion.div>
            </motion.div>
          </motion.div>
          <motion.button
            layout
            type="submit"
            disabled={!formik.isValid || formik.isSubmitting}
            className="w-full mt-4 py-4 disabled:cursor-not-allowed text-3xl font-serif bg-c2 text-w disabled:opacity-50 disabled:hover:bg-c2 disabled:hover:text-w hover:bg-b hover:text-c2 hover:border-c2 border-2 border-b rounded-md flex items-center justify-center transition-all duration-300"
          >
            {formik.isSubmitting ? (
              <svg
                aria-hidden="true"
                className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-c2 fill-c1"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            ) : (
              "Soumettre"
            )}
          </motion.button>
        </motion.div>
      </motion.form>
      <img
        src={home3}
        alt="fête d'enfants"
        className="float-right lg:hidden block mb-10 w-full lg:w-1/2 rounded-md lg:ml-4 lg:mb-2"
      />
      <h2 className="text-w lg:hidden font-serif text-center text-5xl lg:text-7xl mx-auto mb-10">
        Anniversaires
      </h2>
    </motion.div>
  );
};

export default BirthdayForm;
