import React from "react";
import { motion } from "framer-motion";
import laserGame1 from "../assets/karaoke.jpg";

const Karaoke = () => {
  return (
    <div className=" w-screen py-40 px-0  flex flex-col items-center gap-10 text-5xl font-serif bg-b text-w">
      <motion.h2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.3 } }}
        className="mb-10 lg:text-7xl text-c2 underline"
      >
        {" "}
        Karaoké
      </motion.h2>
      <section className="flex flex-col-reverse w-full lg:flex-row items-start gap-4 pb-20 px-4 lg:px-10">
        <div className="w-full h-full flex flex-col font-light items-center justify-center">
          <p className="font-sans text-xl lg:pl-40 w-full px-10">
            🎤 Nouveauté au West Parc ! 🎉 <br />
            <br /> Venez découvrir nos deux nouvelles salles de karaoké ! 🎶{" "}
            <br /> Avec une capacité de 18 personnes par salle, préparez-vous à
            chanter vos hits préférés entre amis ou en famille 🎵🎤 <br />
            <br /> West Parc, c’est LE centre de loisirs de référence à Dinard
            et ses environs, toujours prêt à vous surprendre avec des activités
            inédites et toujours plus fun ! Bowling, laser game, trampoline,
            jeux d’arcade, Billards... et maintenant du karaoké pour des moments
            inoubliables 🤩 <br />
            <br /> Réservez dès maintenant votre session et soyez parmi les
            premiers à tester cette nouvelle expérience ! ✨ On vous attend
            nombreux 😎{" "}
          </p>
          <h3 className="my-4 text-3xl text-c2">Tarifs</h3>
          <p className="font-sans text-xl lg:pl-40 w-full px-10">
            Du lundi au vendredi 19h :{" "}
            <span className="text-c3">
              <br />
              50€ / heure jusqu'à 10 personnes + 5€ par personne supplémentaire
              au-delà{" "}
            </span>{" "}
            <br />
            <br />
            Les weekends à partir de vendredi 19h + jours fériés + vacances
            (zones B et C) : <br />
            <span className="text-c3">
              {" "}
              70€ / heure jusqu'à 10 personnes + 7€ par personne supplémentaire
              au-delà
            </span>
          </p>
        </div>

        <motion.div className="w-4/5 lg:sticky top-44 mx-auto overflow-hidden grid place-content-center">
          <motion.img
            initial={{ opacity: 0, x: 100 }}
            animate={{
              opacity: 1,
              x: 0,
              transition: { duration: 1, ease: "easeIn" },
            }}
            src={laserGame1}
            alt="pistes de bowling"
            className="w-full rounded-l-lg"
          />
        </motion.div>
      </section>
    </div>
  );
};

export default Karaoke;
