import React, { useEffect, useState, Fragment } from "react";
import Event from "../components/Event";
import delirium from "../assets/delirium.png";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import video from "../assets/video.mp4";
import home from "../assets/home.jpeg";
import home2 from "../assets/home2.jpeg";
import home3 from "../assets/home3.jpeg";
import micro from "../assets/micro.svg";
import team1 from "../assets/team1.jpg";
import gun from "../assets/gun.png";
import trampo from "../assets/trampo.png";
import arcade from "../assets/arcade.png";
import bowling from "../assets/bowling1.png";
import logo from "../assets/logoNoBg.png";
import { AiOutlineCloseCircle } from "react-icons/ai";

const Home = ({
  events,
  setVideoLoaded,
  setLogoLoaded,
  videoLoaded,
  logoLoaded,
}) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [popup, setPopup] = useState(false);
  const cards = [
    {
      title: "bowling",
      icon: bowling,
    },
    {
      title: "laser game",
      icon: gun,
    },
    {
      title: "trampoline",
      icon: trampo,
    },
    { title: "karaoké", icon: micro },
    {
      title: "billard et jeux",
      icon: arcade,
    },
    {
      title: "bar",
      icon: delirium,
    },
  ];
  const w = window.innerWidth < 992;

  useEffect(() => {
    const interval = setInterval(() => {
      setPhotoIndex((photoIndex + 1) % 3);
    }, 5000);
    return () => clearInterval(interval);
  }, [photoIndex]);

  useEffect(() => {
    const visited = JSON.parse(localStorage.getItem("visited"));
    const daysPassed = (new Date() - new Date(visited?.timestamp)) / 86400000;
    if (daysPassed > 1) {
      localStorage.removeItem("visited");
    }
    if (events && events.length > 0 && !visited?.value && !popup) {
      setTimeout(() => {
        setPopup(true);
        if (!visited) {
          localStorage.setItem(
            "visited",
            JSON.stringify({ value: true, timestamp: new Date() })
          );
        }
      }, 10000);
    }
  }, [events]);

  return (
    <main
      className={
        (w ? true : videoLoaded) && logoLoaded
          ? "h-fit"
          : "h-screen overflow-hidden"
      }
    >
      <section
        className={`overflow-y-scroll lg:overflow-y-visible flex-col items-center h-screen pt-40 lg:pt-96 lg:flex-row flex w-full lg:w-full mx-auto`}
      >
        <div className="absolute top-0 left-0 w-screen h-screen bg-b bg-opacity-40 z-10" />
        {w ? (
          <div>
            <AnimatePresence>
              {photoIndex === 0 && (
                <motion.img
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{ opacity: 0, transition: { duration: 2 } }}
                  src={home}
                  alt="main"
                  // className="w-full md:absolute top-0 lg:-top-[12vw]" Initial home photo
                  className="h-screen w-screen absolute left-0 top-0 object-cover z-0"
                />
              )}
            </AnimatePresence>
            <AnimatePresence>
              {photoIndex === 1 && (
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{ opacity: 0, transition: { duration: 2 } }}
                  src={home2}
                  alt="Girl standing and smiling"
                  className="h-screen w-screen absolute left-0 top-0 object-cover z-0"
                />
              )}
            </AnimatePresence>
            <AnimatePresence>
              {photoIndex === 2 && (
                <motion.img
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{ opacity: 0, transition: { duration: 2 } }}
                  src={home3}
                  alt="Boy staring to the left"
                  className="h-screen w-screen absolute left-0 top-0 object-cover z-0"
                />
              )}
            </AnimatePresence>
          </div>
        ) : (
          <video
            src={video}
            className="h-screen w-screen absolute left-0 top-0 object-cover z-0"
            autoPlay
            muted
            loop
            controls={false}
            onCanPlay={() => setVideoLoaded(true)}
          ></video>
        )}
        <Link
          to="/horaires-et-tarifs"
          style={{ textShadow: "2px 2px 5px #EE36C0" }}
          className="text-3xl border w-fit py-2 px-4 shadow-[0px_0px_5px_3px_#EE36C0] rounded-md block lg:hidden font-serif z-10 text-w  text-center mb-10"
        >
          Horaires et tarifs
        </Link>
        <h1
          style={{ textShadow: "2px 2px 5px #EE36C0" }}
          className="text-4xl block lg:hidden font-serif z-10 text-w w-full text-center"
        >
          Nos loisirs
        </h1>
        <div className="flex h-fit z-30 flex-col lg:flex-row justify-between w-screen lg:w-full items-center">
          {cards.map((item, index) => (
            <div
              key={index}
              className={` flex flex-col my-10 items-center justify-center h-full lg:w-1/5`}
            >
              <Link
                to={
                  item.title === "karaoké"
                    ? "karaoke"
                    : item.title.toLowerCase().replace(/ /g, "-")
                }
                className="flex flex-col items-center group/card"
              >
                <div
                  className={
                    `w-fit h-fit relative  lg:bg-opacity-50 group-hover/card:bg-opacity-100 group-hover/card:scale-125 rounded-full transition-all duration-300` +
                    (item.title === "karaoké" ? " bg-c4" : " bg-c2")
                  }
                >
                  {item.title === "karaoké" && (
                    <span className="text-c2 text-2xl font-serif z-20 group-hover/card:scale-125 group-hover/card:translate-y-[-50px] group-hover/card:translate-x-[-50px] duration-500 ease-in-out -rotate-[20deg] absolute -top-2  -left-4">
                      Nouveau!
                    </span>
                  )}
                  <img
                    src={item.icon}
                    alt={item.title}
                    onLoad={() => setLogoLoaded(true)}
                    className={`w-40 h-40 ${
                      item.title === "bar" && "rounded-full"
                    } group-hover/card:translate-y-[-50px] filter drop-shadow-lg z-10 group-hover/card:scale-125 transition-all duration-500 ease-in-out`}
                  />
                </div>
                <h2
                  className="font-serif group-hover/card:scale-150  text-3xl group-hover/card:translate-y-6 text-w capitalize shadow-c3 whitespace-nowrap transition-all duration-300 ease-in-out"
                  style={{ textShadow: "2px 2px 5px #EE36C0" }}
                >
                  {item.title}
                </h2>
              </Link>
            </div>
          ))}
        </div>
      </section>
      <section className="text-w pt-20 px-10 lg:px-40 pb-20">
        <img src={logo} alt="logo" className="mb-10 mx-auto" />
        <div className="flex flex-col lg:flex-row">
          <img
            src={team1}
            alt="Des gens qui s'amusent au bowling"
            className="w-full lg:sticky top-52 lg:w-1/2 h-52 lg:mb-0 mb-4 lg:h-96 object-cover mr-10"
          />
          <p className="text-justify">
            Situé à La Richardais en face de Saint Malo, notre complexe de
            loisirs West Parc vous accueille pour partager de grands moments en
            famille, entre amis et avec les enfants. Notre lieu est dédié à la
            convivialité, au plaisir de jouer ensemble, boire un verre en
            écoutant de la musique par exemple.
            <br />
            <br />
            Notre complexe vous propose un bowling dispose de 16 pistes, un
            Laser Game de 600m² pouvant accueillir 20 joueurs en simultané, un
            Trampoline avec parcours Ninja, 8 billards et des jeux d’arcades.
            <br />
            <br />
            Nous proposons régulièrement des soirées Karaoké, Des Scènes
            Ouvertes, des concerts et les évènements sportifs sur notre écran
            géant de 12m².
            <br />
            <br />
            Notre salle dispose du matériel nécessaire pour vos séminaires,
            incentive, team building, show-room, soirées d’entreprise…
            <br />
            <br />
            <span className="text-c2">
              Les mineurs doivent obligatoirement être sous la surveillance d’un
              adulte au sein de l’établissement.
            </span>
            <br />
            <br />
            La vente et consommation d’alcool est interdite aux mineurs. L’abus
            d’alcool est dangereux pour la santé. Consommez avec modération.
          </p>
        </div>
      </section>
      <AnimatePresence>
        {events && events.length > 0 && popup && (
          <motion.div
            onClick={() => setPopup(false)}
            className="h-screen w-screen fixed flex items-center justify-center inset-0 bg-b bg-opacity-50 z-50"
          >
            <Event events={events} setPopup={setPopup} />
          </motion.div>
        )}
      </AnimatePresence>
      {/*  <section className="h-fit w-screen text-w flex flex-col items-center p-10 lg:p-20 pt-40">
        <h2 className="font-serif text-3xl lg:text-5xl mb-10 capitalize">
          happy hour
        </h2>

        <h3 className="text-2xl mb-4 text-center lg:text-4xl text-w">
          <span className="text-c3"> La pinte</span> au prix d'un demi !
        </h3>
        <p>Sur toutes les bières pression.</p>
        <p>Et tous nos softs à 2.50€</p>
        <span className="text-center">
          Du mercredi au vendredi, 18h00-20h00, hors vacances scolaires
        </span>

        <Link
          to="/bar"
          className="relative group/menu w-full lg:w-2/5 h-fit select-none"
        >
          <img
            src={delirium}
            alt="delirium"
            className="w-full mx-auto opacity-100 group-hover/menu:opacity-20 transition-all duration-200"
          />
          <h2 className="absolute whitespace-nowrap opacity-0 group-hover/menu:opacity-100 font-serif text-3xl lg:text-5xl text-c2 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-20 transition-all duration-200">
            Voir le menu
          </h2>
        </Link>
      </section> */}
      {/* <section className="h-screen w-screen flex flex-col items-center p-20 lg:pt-10">
        <h2 className="font-serif text-5xl text-w capitalize">équipe</h2>
      </section> */}
    </main>
  );
};

export default Home;
