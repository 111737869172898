import React from "react";
import mapPin from "../assets/map-pin.png";
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMailOpen, HiOutlineMail } from "react-icons/hi";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className={`w-screen h-fit flex flex-col items-center z-40 `}>
      <div className="w-screen h-fit gap-y-10 lg:h-52 pb-10 flex flex-col lg:flex-row items-center lg:items-start justify-between px-10 lg:px-20 z-40 bg-b bg-opacity-50">
        <a
          className="flex group/map flex-col items-center h-full justify-between"
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.google.com/maps/place/West+Parc/@48.6140688,-2.0591546,14.38z/data=!4m6!3m5!1s0x480e873015756821:0x7a9c73dcfba26d84!8m2!3d48.6118503!4d-2.0478266!16s%2Fg%2F1pp2tl399?entry=ttu"
        >
          <img
            className="w-20 group-hover/map:animate-bounce"
            src={mapPin}
            alt="googe maps pin"
          />
          <div className="flex flex-col">
            <p className="text-center text-w text-2xl">
              {" "}
              1 Rue des Villes Billy
            </p>
            <p className="text-center text-w text-2xl"> 35780 La Richardais</p>
          </div>
        </a>
        <Link
          to="/contact"
          className="group/mail flex flex-col items-center h-full justify-between"
        >
          <div className="relative h-28 w-full">
            <HiOutlineMail
              size={100}
              className="absolute bottom-6 left-1/2 -translate-x-1/2 group-hover/mail:opacity-0 opacity-100 transition-all duration-150 text-c1"
            />
            <HiOutlineMailOpen
              size={100}
              className="absolute bottom-6 left-1/2 -translate-x-1/2 group-hover/mail:opacity-100 opacity-0 transition-all duration-150 text-c1"
            />
          </div>
          <p className="text-center text-w text-2xl">Contactez-nous !</p>
        </Link>
        <a
          className="text-w text-2xl group/phone flex flex-col items-center h-full justify-between"
          href="tel:+33223169768"
        >
          <FaPhoneAlt
            size={75}
            className="mb-4 text-c2 group-hover/phone:animate-wiggle"
          />
          <div className="flex flex-col">
            <h2 className="flex font-serif flex-row ">Appelez-nous!</h2>
            <p>02.23.16.97.68</p>
          </div>
        </a>
        <div className="flex flex-col items-center h-full justify-between group/social">
          <div className="flex flex-row items-start mb-6">
            <a
              target="_blank"
              rel="noreferer noopener"
              href="https://www.facebook.com/profile.php?id=100095433431810"
            >
              <BsFacebook
                size={75}
                className="text-c2 group-hover/social:animate-spin"
              />
            </a>
            <a
              target="_blank"
              rel="noreferer noopener"
              href="https://www.instagram.com/westparc.officiel"
            >
              <BsInstagram
                size={75}
                className="text-c1 group-hover/social:animate-spin"
              />
            </a>
          </div>
          <p className="text-center text-w text-2xl">Suivez-nous !</p>
        </div>
      </div>
      <div className="w-screen h-10 z-50 flex flex-row items-end pb-2 justify-center lg:justify-end lg:px-20 gap-10 bg-b bg-opacity-50">
        <p className="text-w text-sm">© {new Date().getFullYear()} West Parc</p>
        <Link to="/mentions-legales" className="text-w text-sm">
          Mentions légales
        </Link>
      </div>
    </div>
  );
};

export default Footer;
