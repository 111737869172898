import React, { useState, useEffect } from "react";
import logo from "../assets/logoNoBg.png";
import {
  useScroll,
  useTransform,
  motion,
  AnimatePresence,
} from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { CgMenuRight } from "react-icons/cg";
import { MdOutlineClose } from "react-icons/md";

const Nav = () => {
  const { pathname } = useLocation();
  const [navIndex, setNavIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [open, setOpen] = useState(false);

  const nav = [
    "Nos loisirs",
    "Horaires et tarifs",
    "Événements",
    "Anniversaires",
    "Groupes et entreprises",
    "Contact",
  ];
  const { scrollY } = useScroll();
  const p = pathname === "/";
  const w = window.innerWidth < 992;
  const xPos = useTransform(scrollY, [50, 100], [p ? "0vw" : "-40vw", "-40vw"]);
  const rotate = useTransform(scrollY, [0, 150], [p ? 0 : -1080, -1080]);
  const height = useTransform(
    scrollY,
    [0, 100],
    [p ? "45vh" : w ? "10vh" : "15vh", w ? "10vh" : "15vh"]
  );
  const opacity = useTransform(scrollY, [0, 100], [p ? 1 : 0, 0]);
  const opacity2 = useTransform(scrollY, [0, 100], [p ? 0 : 1, 1]);
  const logoHeight = useTransform(
    scrollY,
    [100, 150],
    [p ? "12vh" : "0vh", "0vh"]
  );
  const navWidth = useTransform(
    scrollY,
    [0, 100],
    [p ? "0vw" : "15vw", "15vw"]
  );
  const background = useTransform(
    scrollY,
    [0, 100],
    [p ? "rgba(0, 0, 0, 0)" : "#18181B", "#18181B"]
  );
  const fontSize = useTransform(
    scrollY,
    [0, 100],
    [p ? "1.8vw" : "1.5vw", "1.5vw"]
  );

  useEffect(() => {
    if (pathname === "/") {
      setNavIndex(0);
      setSelectedIndex(0);
    } else if (pathname === "/horaires-et-tarifs") {
      setNavIndex(1);
      setSelectedIndex(1);
    } else if (pathname === "/%C3%A9v%C3%A9nements") {
      setNavIndex(2);
      setSelectedIndex(2);
    } else if (pathname === "/anniversaires") {
      setNavIndex(3);
      setSelectedIndex(3);
    } else if (pathname === "/groupes-et-entreprises") {
      setNavIndex(4);
      setSelectedIndex(4);
    } else if (pathname === "/contact") {
      setNavIndex(5);
      setSelectedIndex(5);
    }
  }, [pathname]);

  return (
    <motion.nav
      layout
      layoutRoot
      className="flex h-fit flex-col items-center fixed top-0 left-0 w-screen  z-50"
      style={{ background: pathname === "/" ? background : "#18181B" }}
    >
      <motion.div style={{ marginTop: w ? "0vh" : logoHeight }}>
        <Link
          to="/"
          className="h-fit w-fit z-50"
          onClick={() => {
            setNavIndex(0);
            setSelectedIndex(0);
          }}
        >
          <AnimatePresence>
            {w ? (
              <div className="text-w flex-col flex items-center">
                <motion.img src={logo} alt="logo" className=" mb-4 w-32" />
                <div className="relative bottom-4 font-serif flex flex-row items-center justify-center w-full gap-2">
                  <h2 className="">Soyons sérieux.</h2>
                  <h2 className="">Amusons-nous.</h2>
                </div>
              </div>
            ) : (
              <motion.div
                style={{
                  translateX: pathname === "/" ? xPos : "-40vw",
                  rotate: pathname === "/" ? rotate : 0,
                  width: pathname === "/" ? height : "15vh",
                }}
                transition={{ duration: 1, type: "spring" }}
                className="flex flex-col items-center text-w font-serif"
              >
                <motion.img
                  src={logo}
                  alt="logo"
                  className="my-4 w-full h-full"
                />
                <motion.div
                  style={{ opacity: opacity }}
                  className="relative w-fit text-center flex bottom-2 text-4xl"
                >
                  <motion.h2
                    className="inline whitespace-nowrap mr-4"
                    initial={{ y: -500, scale: 2, opacity: 0 }}
                    animate={{
                      scale: 1,
                      opacity: 1,
                      y: 0,
                      transition: {
                        duration: 0.1,
                        delay: 1.5,
                        type: "tween",
                      },
                    }}
                  >
                    Soyons sérieux.{" "}
                  </motion.h2>
                  <motion.h2
                    className="inline whitespace-nowrap"
                    animate={{
                      y: [-500, -50, 0],
                      x: [-200, 100, 0],
                      scale: [1, 2, 1],
                      opacity: [1, 1, 1],
                      transition: {
                        delay: 3,
                        type: "spring",
                        bounce: 0.9,
                        duration: 3,
                      },
                    }}
                  >
                    Amusons-nous.
                  </motion.h2>
                </motion.div>
                <motion.div
                  className="absolute bottom-4 w-96 flex flex-row items-center justify-center gap-2"
                  style={{ opacity: opacity2 }}
                >
                  <h2>Soyons sérieux.</h2>
                  <h2>Amusons-nous</h2>
                </motion.div>
              </motion.div>
            )}
          </AnimatePresence>
        </Link>
      </motion.div>
      <motion.ul
        className="absolute hidden lg:flex flex-row justify-between font-sans text-w font-light w-[90%]"
        style={{
          top: "7vh",
          paddingLeft: pathname === "/" ? navWidth : "15vw",
          transition: pathname === "/" ? "all 0.1s ease" : "none",
          fontSize: fontSize,
        }}
        onMouseLeave={() => setNavIndex(selectedIndex)}
      >
        {nav.map((item, index) => (
          <motion.li
            key={index}
            className="relative flex flex-col items-center justify-center w-fit text-center"
            onMouseEnter={() => setNavIndex(index)}
            onClick={() => setSelectedIndex(index)}
          >
            <Link
              to={
                item === "Nos loisirs"
                  ? "/"
                  : item.toLowerCase().replace(/ /g, "-")
              }
              className="z-10"
            >
              {item}
            </Link>
            {navIndex === index && (
              <motion.div
                layoutId="nav"
                className="absolute w-[calc(100%+32px)] -left-[16px] h-[calc(100%+16px)] -top-[8px] border-2 border-w z-0"
                style={{
                  borderRadius: "10px",
                  boxShadow: "0 0 5px 5px #56bbe5, inset 0 0 3px 3px #56bbe5",
                }}
              />
            )}
          </motion.li>
        ))}
      </motion.ul>
      <button
        onClick={() => setOpen(true)}
        className="absolute top-6 right-6 text-w lg:hidden"
      >
        <CgMenuRight size={40} />
        <p>Menu</p>
      </button>
      <AnimatePresence>
        {open && (
          <motion.div
            initial={{ clipPath: "ellipse(0vw 0vh at 95% 5%)" }}
            animate={{
              clipPath: "ellipse(150vw 150vh at 95% 5%)",
              transition: { duration: 0.5 },
            }}
            exit={{
              clipPath: "ellipse(0vw 0vh at 95% 5%)",
              transition: { duration: 0.2 },
            }}
            onClick={() => setOpen(false)}
            className="bg-b fixed inset-0 flex flex-col items-center z-40 origin-top-right"
          >
            <button
              onClick={() => setOpen(true)}
              className="absolute flex flex-col items-center top-6 right-4 text-w"
            >
              <MdOutlineClose size={40} />
              <p>Fermer</p>
            </button>
            <ul className="flex flex-col gap-10 mt-44 text-2xl font-serif ">
              {nav.map((item, index) => (
                <li key={index}>
                  <Link
                    to={
                      item === "Nos loisirs"
                        ? "/"
                        : item.toLowerCase().replace(/ /g, "-")
                    }
                    className="text-w"
                  >
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
};

export default Nav;
